export function cleanText(text) {
    return text.replaceAll('&nbsp;&nbsp;&nbsp;', ' ')
        .replaceAll('&nbsp;&nbsp;', ' ')
        .replaceAll('&nbsp;', ' ')
        .replaceAll('<p>', '')
        .replaceAll('</p>', '')
        .trim()
}

export function normalizeText(text) {
    return text.toLowerCase().replace(/\s+/g, '').trim()
}

export async function generateSHA1(message) {
    const msgBuffer = new TextEncoder().encode(message); // Codifica la stringa in un buffer di byte
    const hashBuffer = await crypto.subtle.digest('SHA-1', msgBuffer); // Calcola l'hash
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // Converte il buffer in array di byte
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // Converte l'array in esadecimale
    return hashHex;
}

export function decodeToken(token) {
    const [, payload] = token.split(".");

    if (typeof payload !== "string") {
        throw new Error("Unable to decode token, payload not found.");
    }

    let decoded;

    try {
        decoded = base64UrlDecode(payload);
    } catch (error) {
        throw new Error("Unable to decode token, payload is not a valid Base64URL value.", { cause: error });
    }

    try {
        return JSON.parse(decoded);
    } catch (error) {
        throw new Error("Unable to decode token, payload is not a valid JSON value.", { cause: error });
    }
}

export function base64UrlDecode(input) {
    let output = input
        .replaceAll("-", "+")
        .replaceAll("_", "/");

    switch (output.length % 4) {
        case 0:
            break;
        case 2:
            output += "==";
            break;
        case 3:
            output += "=";
            break;
        default:
            throw new Error("Input is not of the correct length.");
    }

    try {
        return b64DecodeUnicode(output);
    } catch (error) {
        return atob(output);
    }
}

function b64DecodeUnicode(input) {
    return decodeURIComponent(atob(input).replace(/(.)/g, (m, p) => {
        let code = p.charCodeAt(0).toString(16).toUpperCase();

        if (code.length < 2) {
            code = "0" + code;
        }

        return "%" + code;
    }));
}
<template>
  <v-app>
    <v-container>
      <h1 class="text-center">University Questions Manager (UQM)</h1>

      <v-row class="mb-0 mt-1">
        <v-tabs v-model="tab" cols="10">
          <v-tab value="0" v-if="isUploader">Inserisci Domanda</v-tab>
          <v-tab value="1">Tutorial</v-tab>
          <v-tab value="2">Ricerca Domanda</v-tab>
          <v-tab value="3">Feedback</v-tab>
        </v-tabs>

        <v-col align="end">
          <v-btn color="light-blue" class="pa-1 mr-2" @click="openPayPalLink">
            <v-icon left><i class="fab fa-paypal"></i></v-icon>Supporto
            <v-icon class="ml-1"><i class="fas fa-mug-hot"></i></v-icon>
          </v-btn>
          <v-btn v-if="!isAuthenticatedRef" color="primary" @click="login">Login</v-btn>
          <v-btn v-if="isAuthenticatedRef" color="secondary" @click="logout">Logout</v-btn>
          <!--<v-btn @click="toggleDarkTheme">Toggle Theme</v-btn>-->
        </v-col>
      </v-row>

      <v-tabs-window v-model="tab" class="pa-1">
        <v-tabs-window-item value="0" v-if="isUploader">
          <UploadQuestion>
            <CourseSelector :courseNames="courseNames" :filteredTeachings="filteredTeachings"
              :loadingCourses="loadingCourses" :selectedCourse="selectedCourse" :selectedTeaching="selectedTeaching"
              :coursesDatalength="coursesData.length"
              @update:selectedTeaching="(value) => { console.log('Selected Teaching Updated:', value); selectedTeaching = value }"
              @update:selectedCourse="(value) => { console.log('Selected Course Updated:', value); selectedCourse = value }"
              :domandeTotali="domandeTotali" showTotali />
          </UploadQuestion>
        </v-tabs-window-item>

        <v-tabs-window-item value="1">
          <TutorialGuide />
        </v-tabs-window-item>

        <v-tabs-window-item value="2">
          <v-card>
            <v-card-title>Cerca Domanda</v-card-title>
            <v-card-text>
              <CourseSelector :courseNames="courseNames" :filteredTeachings="filteredTeachings"
                :loadingCourses="loadingCourses" :selectedCourse="selectedCourse" :selectedTeaching="selectedTeaching"
                :coursesDatalength="coursesData.length"
                @update:selectedTeaching="(value) => { console.log('Selected Teaching Updated:', value); selectedTeaching = value }"
                @update:selectedCourse="(value) => { console.log('Selected Course Updated:', value); selectedCourse = value }"
                :domandeTotali="domandeTotali" showTotali />
              <v-text-field v-model="searchQuery" label="Cerca Domanda" @input="searchQuestions" class="mb-0"
                :disabled="selectedTeaching === ''"></v-text-field>
              <v-text-field v-model="searchSubQuery" label="Filtra risultati" @input="searchSubQuestions"
                class="mb-3 mt-0" :disabled="searchQuery.trim().length <= 2"></v-text-field>

              <v-list class="scrollable-list">
                <v-list-item-group>
                  <v-list-item v-for="question in searchResults" :key="question.id" class="question-item">
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold display-content multiline v-list-item--multiline"
                        v-html="question.domanda" />
                      <v-list-item-subtitle class="text-right mt-1 multiline v-list-item--multiline"
                        v-html="question.risposta" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="searchResults.length > 0"></v-divider>
                  <v-list-item v-if="searchResults.length === 0">
                    <v-list-item-content>
                      <v-list-item-title>No results found.</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-tabs-window-item>

        <v-tabs-window-item value="3">
          <FeedbackForm />
        </v-tabs-window-item>
      </v-tabs-window>
    </v-container>

    <!-- Footer -->
    <v-footer app padless>
      <v-col class="text-center pa-0" cols="12">
        <v-divider></v-divider>
        <p class="footer-text">© 2024 Question Managing System - Version {{ appVersion }}</p>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { ref, watch, computed, onMounted, provide } from 'vue'
import { useTheme } from 'vuetify'
import { axiosI, fetchWithCache } from './axios'
import { isAuthenticated, login, logout, hasResourceRole } from './keycloak'
import FeedbackForm from "@/components/FeedbackForm.vue"
import CourseSelector from "@/components/CourseSelector.vue"
import UploadQuestion from "@/components/UploadQuestion.vue"
import TutorialGuide from "@/components/TutorialGuide.vue"

const QUERY_DELAY = 1 * 1000

export default {
  /*methods: {
    checkAdblock() {
      const ADS_URL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'

      function checkAdsBlocked(callback) {
        const xhr = new XMLHttpRequest()
        xhr.onreadystatechange = function () {
          if (xhr.readyState == XMLHttpRequest.DONE) callback(xhr.status === 0 || xhr.responseURL !== ADS_URL)
        }
        xhr.open('HEAD', ADS_URL, true)
        xhr.send(null)
      }

      checkAdsBlocked((adsBlocked) => {
        if (adsBlocked) this.showAdblockMessage()
      })
    },

    showAdblockMessage() {
      document.body.innerHTML = ''

      const message = document.createElement('div')
      message.classList.add('adblock-banner')
      message.textContent = 'Per favore, disattiva Adblock per continuare a navigare su questo sito.'
      document.body.appendChild(message)
    }
  },
  mounted() {
    this.checkAdblock()
  },*/
  components: {
    FeedbackForm,
    CourseSelector,
    UploadQuestion,
    TutorialGuide
  },
  setup() {
    const appVersion = '2.3.0'
    const forceUpdate = ref(0)
    const tab = ref("1")
    const coursesData = ref([''])
    const selectedCourse = ref('')
    const selectedTeaching = ref('')
    const searchQuery = ref('')
    const searchSubQuery = ref('')
    const searchResults = ref([])
    const domandeTotali = ref('0')
    const loadingCourses = ref(false)
    let lastQuerySearch = ''
    let lastQuerySearchTime = null
    const isAuthenticatedRef = ref(isAuthenticated())
    const theme = useTheme()
    const isUploader = ref(hasResourceRole('uploader', 'chrome-extension-client'))

    provide('selectedCourse', selectedCourse)
    provide('selectedTeaching', selectedTeaching)

    // Funzione per alternare il tema
    const toggleDarkTheme = () => {
      theme.global.name.value = theme.global.name.value === 'light' ? 'dark' : 'light'
    }

    // Rendi reattiva la variabile di autenticazione
    const checkAuth = () => {
      isAuthenticatedRef.value = isAuthenticated()
    }

    // Aggiungi un watcher per controllare l'autenticazione
    watch(isAuthenticatedRef, () => {
      if (!isAuthenticatedRef.value) checkAuth()
    })

    const getCoursesData = async () => {
      loadingCourses.value = true
      axiosI.get('/course')
        .then(response => {
          coursesData.value = response.data.courses
          loadingCourses.value = false
        })
        .catch(() => {
          loadingCourses.value = false
        })
    }

    const courseNames = computed(() => coursesData.value.map((courseObj) => courseObj.course))

    const filteredTeachings = computed(() => {
      forceUpdate
      if (!selectedCourse.value) return []
      const selectedCourseObj = coursesData.value.find(courseObj => courseObj.course === selectedCourse.value)
      return selectedCourseObj ? selectedCourseObj.teachings : []
    })

    const searchQuestions = () => {
      searchSubQuery.value = ''
      searchSubQuestions()
    }

    const searchSubQuestions = () => {
      let onlyCached = true
      if (lastQuerySearch !== `${searchQuery.value}_${searchSubQuery.value}`.replaceAll(' ', '')
        && (Date.now() - lastQuerySearchTime) > QUERY_DELAY) {
        lastQuerySearchTime = Date.now()
        lastQuerySearch = `${searchQuery.value}_${searchSubQuery.value}`.replaceAll(' ', '')
        onlyCached = false
      }
      fetchWithCache('/question',
        {
          corso: selectedCourse.value,
          insegnamento: selectedTeaching.value,
          query: searchQuery.value,
          subQuery: searchSubQuery.value
        }, onlyCached)
        .then(response => {
          if (response != null) searchResults.value = response
        })
        .catch(console.error)
    }

    const getTotal = () => fetchWithCache('/question/count', {
      corso: selectedCourse.value,
      insegnamento: selectedTeaching.value
    }).then(response => domandeTotali.value = response.total)
      .catch(console.error)

    watch(selectedTeaching, (newTeaching) => {
      if (newTeaching) {
        searchResults.value = []
        getTotal()
      }
    })

    watch(selectedCourse, (newCourse, oldCourse) => {
      if (newCourse) {
        searchResults.value = []
        if (newCourse !== oldCourse) {
          forceUpdate.value += 1
          selectedTeaching.value = ''
        }
        getTotal()
      }
    })

    onMounted(() => {
      if (isAuthenticatedRef.value) getCoursesData()
    })

    const openPayPalLink = () => {
      const paypalLink = "https://paypal.me/EminDemiri?country.x=IT&locale.x=it_IT"
      window.open(paypalLink, "_blank")
    }

    return {
      appVersion,
      tab,
      coursesData,
      selectedCourse,
      selectedTeaching,
      searchQuery,
      searchSubQuery,
      searchResults,
      searchQuestions,
      searchSubQuestions,
      domandeTotali,
      loadingCourses,
      courseNames,
      filteredTeachings,
      isAuthenticatedRef,
      login,
      logout,
      toggleDarkTheme,
      isUploader,
      openPayPalLink
    }
  },
}
</script>

<style>
.v-card {
  margin: 20px 0;
  background-color: #ffffff;
  /* Colore di sfondo della scheda */
  color: #333;
  /* Colore del testo */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Leggera ombra */
}

.question-item {
  padding: 15px;
  background-color: #f5f5f5;
  /* Colore di sfondo per le domande */
  border-radius: 4px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
  /* Transizione per il colore di sfondo */
}

.question-item:hover {
  background-color: #ebebeb;
  /* Colore al passaggio del mouse */
}

.font-weight-bold {
  font-weight: 600;
}

.text-right {
  text-align: right;
  /* Allinea il testo a destra */
}

.v-divider {
  margin: 10px 0;
}

body {
  background-color: #f0f0f0;
  /* Colore di sfondo generale */
}

.scrollable-list {
  max-height: calc(80vh - 200px);
  overflow-y: auto;
}

.v-list-item {
  padding: 10px;
}

.footer-text {
  font-size: 0.7rem;
  /* Dimensione del font desiderata */
}

.display-content {
  display: contents;
}

.display-flex {
  display: flex;
}

.multiline {
  white-space: normal;
}
</style>

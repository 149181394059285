<template>
    <v-row class="mb-3 mt-2">
        <v-col cols="12" md="5" class="pb-0 pt-0">
            <v-select v-model="selectedCourseLocal" :items="courseNames" label="Seleziona corso"
                :loading="loadingCourses" :disabled="loadingCourses || coursesDatalength === 0"
                @update:modelValue="updateCourse" />
        </v-col>
        <v-col cols="12" md="5" class="pt-0 pb-0">
            <v-select v-model="selectedTeachingLocal" :items="filteredTeachings" label="Seleziona insegnamento"
                :disabled="!selectedCourseLocal || loadingCourses" @update:modelValue="updateTeaching" />
        </v-col>
        <v-col v-if="showTotali" cols="12" md="2" align="center" justify="center">
            <v-chip color="primary" label>
                Domande: <b>{{ domandeTotali }}</b>
            </v-chip>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        courseNames: {
            type: Array,
            required: true
        },
        filteredTeachings: {
            type: Array,
            required: true
        },
        loadingCourses: {
            type: Boolean,
            default: false
        },
        selectedCourse: {
            type: String,
            required: true
        },
        selectedTeaching: {
            type: String,
            required: true
        },
        domandeTotali: {
            type: String,
            required: false
        },
        showTotali: {
            type: Boolean,
            default: false
        },
        coursesDatalength: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            selectedTeachingLocal: this.selectedTeaching,
            selectedCourseLocal: this.selectedCourse
        }
    },
    watch: {
        selectedCourse(newVal) {
            this.selectedCourseLocal = newVal
        },
        selectedTeaching(newVal) {
            this.selectedTeachingLocal = newVal
        }
    },
    methods: {
        updateTeaching(value) {
            console.log('updateTeaching: ', value)
            this.$emit('update:selectedTeaching', value)
        },
        updateCourse(value) {
            console.log('updateCourse: ', value)
            this.$emit('update:selectedCourse', value)
        }
    }
};
</script>

import Keycloak from 'keycloak-js';
import { decodeToken } from './utils'

const keycloak = new Keycloak({
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID
});

const session = {
    decodedToken: null
}

const initKeycloak = () => {
    return new Promise((resolve, reject) => {
        keycloak.init({
            onLoad: 'login-required',
            checkLoginIframe: false
        }).then(authenticated => {
            console.log(authenticated)
            if (authenticated) {
                console.log('Authenticated!');
                resolve();
                session.decodedToken = decodeToken(keycloak.token)
                //console.log('decodedToken: ', session.decodedToken)
            } else {
                console.warn('Not authenticated', authenticated);
                reject();
            }
        }).catch(err => {
            console.error('Failed to initialize Keycloak', err);
            reject(err);
        });
    });
};

const login = () => keycloak.login();
const logout = () => keycloak.logout();

const getToken = () => keycloak.token;
const isAuthenticated = () => keycloak.authenticated;

const getUserEmail = () => session.decodedToken.email
const getUserFullName = () => session.decodedToken.name
const hasResourceRole = keycloak.hasResourceRole

export { initKeycloak, login, logout, getToken, isAuthenticated, hasResourceRole, getUserEmail, getUserFullName };
<template>
    <v-card outlined class="pa-3">
        <v-card-title class="text-h5 font-weight-bold">
            Come Cercare Domande
        </v-card-title>
        <v-card-text>
            <v-list dense>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">
                            1. Seleziona un Corso
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            Inizia scegliendo un corso dalla lista dei corsi disponibili. Questa
                            selezione è obbligatoria per
                            procedere con la ricerca.
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider />

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">
                            2. Inserisci una Parola Chiave
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            Digita almeno 3 caratteri nel campo "Cerca Domanda" per avviare la ricerca.
                            La ricerca è sensibile
                            al contesto del corso selezionato.
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider />

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">
                            3. Filtra i Risultati
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            Usa il campo "Filtra risultati" per affinare la ricerca. Inserisci una
                            parola chiave più specifica
                            per ridurre il numero di risultati mostrati.
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider />

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-medium">
                            4. Visualizza Domande e Risposte
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            Ogni risultato mostra la domanda e la risposta associata. Se desideri
                            maggiori dettagli, puoi
                            cliccare per espandere il contenuto.
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-alert type="info" class="mt-4">
                <strong>Nota:</strong> Se non trovi risultati, verifica di aver selezionato il corso
                corretto e
                controlla l'accuratezza della parola chiave.
            </v-alert>
        </v-card-text>
    </v-card>
</template>
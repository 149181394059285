<template>
    <v-card>
        <v-card-title class="text-h6">Feedback o Supporto</v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid">
                <input v-model="formData.name" label="Nome" type="hidden" />
                <input v-model="formData.email" label="Email" type="hidden" />
                <v-textarea v-model="formData.message" label="Messaggio" required
                    :rules="[rules.required, rules.minLength]"></v-textarea>
                <v-row>
                    <v-col cols="6" md="6">
                        <VueClientRecaptcha :value="formData.captcha" @isValid="checkValidCaptcha" />
                    </v-col>
                    <v-col cols="6" md="6">
                        <v-text-field v-model="formData.captcha" label="captcha" required
                            :rules="[rules.required, rules.captcha]" />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn :disabled="!valid" color="primary" @click="submitFeedback">
                Invia
            </v-btn>
            <v-btn text @click="resetForm">Reset</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { getUserEmail, getUserFullName } from '../keycloak'
import VueClientRecaptcha from 'vue-client-recaptcha'
import { axiosI } from '../axios'
import Swal from 'sweetalert2'

export default {
    components: {
        VueClientRecaptcha,
    },
    data() {
        return {
            formData: {
                name: getUserFullName(),
                email: getUserEmail(),
                message: "",
                captcha: ""
            },
            valid: false,
            captchaValid: false,
            rules: {
                required: (value) => !!value || "Campo obbligatorio",
                email: (value) =>
                    /.+@.+\..+/.test(value) || "Email non valida",
                minLength: (value) =>
                    (value && value.trim().length >= 30) || "Deve contenere almeno 30 caratteri",
                captcha: () => this.captchaValid || "Inserisci il captcha corretto"
            },
        };
    },
    methods: {
        checkValidCaptcha(value) {
            this.captchaValid = value
        },
        async submitFeedback() {
            if (this.$refs.form.validate()) {
                axiosI.post('/feedback', {
                    email: this.formData.email,
                    name: this.formData.name,
                    message: this.formData.message
                }).then(response => {
                    Swal.fire({
                        title: response.data.message,
                        icon: 'success'
                    })
                    this.resetForm()
                }).catch(error => {
                    console.error(error)
                    Swal.fire({
                        title: "Errore durante l'invio del feedback'",
                        text: error.message,
                        icon: 'error'
                    })
                });
            }
        },
        resetForm() {
            this.$refs.form.reset()
            this.$refs.form.resetValidation()
            this.formData.message = ""
            this.formData.captcha = ""
        },
    },
};
</script>

<style lang="css">
.vue_client_recaptcha {
    display: flex;
    justify-content: center;
    flex-direction: row
}

.vue_client_recaptcha_icon {
    text-align: center;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    transition: background-color .3s ease-in-out
}

.vue_client_recaptcha_icon:hover {
    background-color: #ccc
}

.vue_client_recaptcha .captcha_canvas {
    background: #eee;
    padding: 10px 0
}

button.swal2-styled {
    color: #fff;
}
</style>

<template>
    <v-card>
        <v-card-title>Aggiungi una Domanda</v-card-title>
        <v-card-text>
            <slot />
            <v-textarea :disabled="selectedTeaching === ''" v-model="question" label="Domanda"
                hint="Inserisci la domanda qui" class="mb-3" />
            <v-textarea :disabled="selectedTeaching === '' || question === ''" v-model="answer" label="Risposta"
                hint="Inserisci qui la risposta" class="mb-3" />
        </v-card-text>
        <v-card-actions>
            <v-btn :disabled="selectedTeaching === '' || question === '' || answer === ''" color="primary"
                @click="addQuestion">Aggiungi Domanda</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { axiosI } from '../axios'
import { ref, inject } from 'vue'
import { cleanText, normalizeText, generateSHA1 } from '../utils'
import Swal from 'sweetalert2'

export default {
    setup() {
        const question = ref('');
        const answer = ref('');
        const selectedTeaching = inject('selectedTeaching');
        const selectedCourse = inject('selectedCourse');

        const addQuestion = async () => {
            const normalizedQuestion = normalizeText(question.value)
            const normalizedResponse = normalizeText(answer.value)
            axiosI.post('/question', {
                corso: selectedCourse.value,
                insegnamento: selectedTeaching.value,
                domanda: cleanText(question.value),
                risposta: cleanText(answer.value),
                hash: await generateSHA1(normalizedQuestion + normalizedResponse),
                questionHash: await generateSHA1(normalizedQuestion)
            }).then(response => {
                Swal.fire({
                    title: response.data.message,
                    icon: 'success'
                })
                question.value = '';
                answer.value = '';
            }).catch(error => {
                console.error(error)
                Swal.fire({
                    title: "Errore durante l'upload della domanda",
                    text: error.message,
                    icon: 'error'
                })
            });
        };

        console.log('selectedTeaching: ', selectedTeaching.value)
        return {
            addQuestion,
            question,
            answer,
            selectedTeaching,
            selectedCourse
        }
    }
};
</script>